import React from "react";
import Head from "next/head";
import styles from "./Loader.module.scss"

export default function NewLoader() {
    return (
        <>
            <section className={styles.new_loader_main} role="alertdialog" aria-busy="true" aria-live="polite" aria-label="Loading…">
                <div className={styles.newloader}></div>
            </section>
        </>
    )
}