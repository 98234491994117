import Link from 'next/link';
import React from 'react';
import styles from './Reusable.module.scss';
import Image from 'next/image';

const ContactLink = React.memo(({ phoneNumber, iconSrc }) => (
  <Link href={`tel:${phoneNumber}`} className={styles.room_contact_no}>
    <div className='icon_con'>
      <Image src={iconSrc} height={21} width={21} alt="contact-icon" />
    </div>
    <p className='m-0'>{phoneNumber}</p>
  </Link>
));

export default ContactLink;