import { useEffect, useState } from 'react';
import styles from './Banner.module.scss';

export default function HomePageVideo() {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    useEffect(() => {
        const loadYouTubeAPI = () => {
            if (!window.YT) {
                const script = document.createElement('script');
                script.src = 'https://www.youtube.com/iframe_api';
                script.async = true;
                script.defer = true;
                script.onload = () => console.log('YouTube API script loaded');
                script.onerror = () => {
                    console.error('Failed to load YouTube API script');
                };
                document.body.appendChild(script);
            }
        };

        const onYouTubeIframeAPIReady = () => {
            if (window.YT && window.YT.Player) {
                new window.YT.Player('player', {
                    height: '1180px',
                    width: '1920px',
                    videoId: 'keTV9UFHNpA',
                    playerVars: {
                        autoplay: 1,
                        mute: 1,
                        loop: 1,
                        playsinline: 1,
                        modestbranding: 1,
                        autohide: 1,
                        showinfo: 0,
                        rel: 0,
                        controls: 0,
                        playlist: 'keTV9UFHNpA',
                    },
                    events: {
                        onReady: onPlayerReady,
                        onStateChange: onPlayerStateChange,
                    },
                });
            }
        };

        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

        const handleScroll = () => {
            if (window.scrollY > 300) {
                loadYouTubeAPI();
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function onPlayerReady(event) {
        event.target.playVideo();
        event.target.setPlaybackQuality('hd720');
        setIsVideoLoaded(true);
    }

    function onPlayerStateChange(event) {
        if (event.data === window.YT.PlayerState.ENDED) {
            event.target.playVideo();
        }
    }

    return (
        <>
            {!isVideoLoaded && <p>Loading video...</p>}
            <section className={styles.banner_iframe_video}>
                <div id="player"></div>
            </section>
        </>
    );
}
