import styles from "./Reusable.module.scss";
import Link from 'next/link';
import Image from 'next/image';

export default function AboutSection() {
    return (
        <section className="common_padding">
            <div className="container ">
                <div className={`${styles.about_sec_main} row align-items-center`}>
                    <div className={`${styles.about_sec_main_left} col-lg-5 col-md-12 col-sm-12`}>
                        <div className="common_title">
                            <span>Luxury Hotel In Kufri, Shimla </span>
                            <h1 className="text-capitalise">
                                The Twin Towers Hotel
                            </h1>
                            <p>One of the best Luxury Hotel in Kufri, The Twin Towers Hotel, is located in the heart of town, amid the Himalayan foothills. It offers a beautiful retreat in a mountainous neighborhood, providing visitors with a tranquil, calm, and pleasant atmosphere for a soul-filling holiday. Being so near to nature offers a choice of activities that will nourish your spirit. It will undoubtedly assist you in unwinding while you participate in the most exciting activities with your loved ones at and around the resort. This is the best hotel in Shimla, Kufri for fun activities such as adventure sports, walks, and trails. Whether you're looking for an affordable hotel in Shimla or a budget hotel in Shimla, The Twin Towers Hotel is the perfect choice for your stay. Book your stay at one of the top 4-star hotels in Kufri with online hotel booking in Shimla for a memorable experience in Himachal.
                            </p>
                            <Link href="/about-us" className="secound_common_btn">About Us</Link>
                        </div>
                    </div>
                    <div className={`${styles.about_sec_main_right} col-lg-7 col-md-12 col-sm-12`}>
                        <div className={`${styles.img_container} ${styles.first_img}`}>
                            <img  src="/images/about-sec-1.webp" alt="Hotel Twin Tower"  />
                            {/* <Image
                                src="/images/about-sec-1.webp"
                                fill
                                alt="Hotel Twin Tower"
                                sizes="(max-width: 767px) 200px, (max-width: 1024px) 280px, 328px"
                            /> */}
                        </div>
                        <div className={`${styles.img_container} ${styles.centered_img}`}>
                        <img  src="/images/about-sec-2.webp" alt="Hotel Twin Tower Restaurant Image" />

                            {/* <Image
                                src="/images/about-sec-2.webp"
                                fill
                                alt="Hotel Twin Tower Restaurant Image"
                                sizes="(max-width: 767px) 200px, (max-width: 1024px) 280px, 328px"
                            /> */}
                        </div>
                        <div className={`${styles.img_container} ${styles.last_img}`}>
                        <img  src="/images/about-sec-3.webp" alt="Hotel Twin Tower Room Image" />

                            {/* <Image
                                src="/images/about-sec-3.webp"
                                fill
                                alt="Hotel Twin Tower Room Image"
                                sizes="(max-width: 767px) 200px, (max-width: 1024px) 280px, 328px"
                            /> */}
                        </div>
                    </div>
                    <div className={styles.about_contact_no}>
                        <Link href="tel:9318192605">
                            <span>
                                <Image src="/icons/call-black.svg" height={21} width={21} alt="call-black-icon" />
                            </span>
                            <p> +91 93181-92605</p>
                        </Link>
                    </div>
                </div>
            </div>
        </section >
    )
}
